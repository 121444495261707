export interface AuthInterface {
  isAuthenticated: boolean;
  loadWidjets: boolean;
  loadWidjetsOnLogin: boolean;
  isBorderRed: boolean;

  authenticateUser: (token: string) => void;
  loginUser: (name: string, role: string, id: string, role_id: string, options: any, user_id : string,full_name:string) => void;
  logoutUser: () => void;
  logoutMasqueradedUser: (callback:any) => void;
  checkAuthentication: () => boolean;
  checkAuthenticationManagerOrOP: () => boolean;
  checkAuthenticationAdmin: () => boolean;
  setLoadWidjetsOnLogIn: (setLoadWidjetsOnLogIn: boolean) => void;
  setBorderRed: (setLoadWidjets: boolean) => void;
}

export const defaultAuthContext: AuthInterface = {
  isAuthenticated: false,
  loadWidjets: false,
  loadWidjetsOnLogin: false,
  isBorderRed: false,

  authenticateUser: (token: string) => {},
  loginUser: (name: string, role: string, id: string, role_id: string,full_name:string, options: any) => {},
  logoutUser: () => {},
  logoutMasqueradedUser: (callback:any) => {},
  setLoadWidjetsOnLogIn: () => {},
  setBorderRed: () => {},
  checkAuthentication: () => false,
  checkAuthenticationManagerOrOP: () => false,
  checkAuthenticationAdmin: () => false,
};

export interface AuthStateInterface {
  isAuthenticated: boolean;
  token: string;
  loadWidjetsOnLogin: boolean;
  loadWidjets: boolean;
  isBorderRed: boolean;
}
